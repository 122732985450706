import { Link, withPrefix } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import * as Styles from './footer.module.scss'
import { COMPANY_SITE_URL, PRIVACY_POLICY_URL } from '@/constants/url'
import { pagesPath } from '@/lib/pagesPath'

export function Footer() {
  return (
    <div className={Styles.footer}>
      <div className={Styles.footer__inner}>
        <div className={Styles.footer__box_sp}>
          <Link to={pagesPath.contactUser.$url()}>
            <p className={Styles.footer__box1}>Uniforceをご利用中のお客様</p>
          </Link>
          <Link to={pagesPath.contact.$url()}>
            <p className={Styles.footer__box2}>お問合せ</p>
          </Link>
        </div>
        <div className={Styles.footer__list}>
          <div className={Styles.footer__footerwrap}>
            <ul className={Styles.footer__nav}>
              <li>
                <AnchorLink to={pagesPath.$url()}>Uniforceについて</AnchorLink>
              </li>
              <li>
                <Link to={pagesPath.plan.$url()}>料金プラン</Link>
              </li>
            </ul>
            <ul className={Styles.footer__nav}>
              <li>
                <Link to={pagesPath.tool.$url()}>主な機能</Link>
              </li>
              <li>
                <Link to={pagesPath.news.$url()}>お知らせ</Link>
              </li>
            </ul>
            <ul className={Styles.footer__nav}>
              <li>
                <Link to={pagesPath.security.$url()}>セキュリティ</Link>
              </li>
              <li>
                <Link to={pagesPath.faq.$url()}>よくあるご質問</Link>
              </li>
            </ul>
          </div>
          <div className={Styles.footer__footerwrap_sp}>
            <div className={Styles.footer__footerwrap_sp__flex}>
              <ul className={Styles.footer__nav_sp}>
                <li>
                  <AnchorLink to={pagesPath.$url()} stripHash>
                    Uniforceについて
                  </AnchorLink>
                </li>
                <li>
                  <Link to={pagesPath.security.$url()}>セキュリティ</Link>
                </li>
                <li>
                  <Link to={pagesPath.news.$url()}>お知らせ</Link>
                </li>
              </ul>
              <ul className={Styles.footer__nav_sp}>
                <li>
                  <Link to={pagesPath.tool.$url()}>主な機能</Link>
                </li>
                <li>
                  <Link to={pagesPath.plan.$url()}>料金プラン</Link>
                </li>
                <li>
                  <Link to={pagesPath.faq.$url()}>よくあるご質問</Link>
                </li>
              </ul>
            </div>

            <Link
              to="https://www.cscloud.co.jp/dx-security"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/footer_banner.jpg"
                alt="日本のDXをもっと安全に"
                height={53.12}
              />
            </Link>
          </div>
          <footer>
            <Link
              to="https://www.cscloud.co.jp/dx-security"
              target="_blank"
              rel="noopener noreferrer"
              className="pc"
            >
              <StaticImage
                src="../../images/footer_banner.jpg"
                alt="日本のDXをもっと安全に"
                height={53.12}
              />
            </Link>
            <ul className={Styles.footer__nav_small}>
              <li>
                <a href={COMPANY_SITE_URL} target="_blank" rel="noreferrer">
                  運営会社
                </a>
              </li>
              <li>
                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                  プライバシーポリシー
                </a>
              </li>
              <li>
                <Link to={pagesPath.rule.$url()}>利用規約</Link>
              </li>
            </ul>
            <small>© Uniforce All Right Reserved.</small>
          </footer>
        </div>
        <div className={Styles.footer__box_pc}>
          <Link to={pagesPath.contactUser.$url()}>
            <p className={Styles.footer__box1}>Uniforceをご利用中のお客様</p>
          </Link>
          <Link to={pagesPath.contact.$url()}>
            <p className={Styles.footer__box2}>お問合せ</p>
          </Link>
        </div>
        <AnchorLink to={pagesPath.$url()} className={Styles.footer__logo_pc}>
          <div>
            <img
              src={withPrefix('/footer_icon_service-logo.svg')}
              alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
              width={115.76}
              height={127.13}
            />
          </div>
        </AnchorLink>
        <AnchorLink to={pagesPath.$url()} className={Styles.footer__logo_sp}>
          <div>
            <img
              src={withPrefix('/footer_icon_service-logo-sp.svg')}
              alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
              width={93.74}
              height={91.94}
            />
          </div>
          <small>© Uniforce All Right Reserved.</small>
        </AnchorLink>
      </div>
    </div>
  )
}
